<template>
	<div class="half_layer_wrap">
		<div class="half_layer_wrap_inner">
			<article class="half_view">
				<div class="layer_title">
					<div class="tit_tab">
						<h3 class="focus">가입 카르텔</h3>
					</div>
				</div>
				<div class="layer_table">
					<table class="nft_info_table">
						<colgroup>
							<col style="width: 75%">
							<col style="width: 25%">
						</colgroup>
						<tbody>
						<template
							v-if="items.length > 0"
						>
							<tr
								v-for="(item, index) in items"
								:key="'item_' + index"
							>
								<td @click="$bus.$emit('to', { name: 'mafia049', params: {idx: item.cartl_number, from: $route.name}})">{{ item.cartl_name }}</td>
							</tr>
						</template>
						<tr
							v-else
						>
							<td colspan="3" class="td_list_none">
								<div class="list_none">
									<img :src="require('@/assets/image/list_none.png')" alt="이력없음">
									<span>가입된 카르텔이 없습니다.</span>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</article>
			<button class="btn_close" @click="$emit('cancel')">
				<i class="icon icon-close"></i>
			</button>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
export default {
	name: 'mafia0981'
	, props: ['user', 'member']
	, data: function(){
		return {
			program: {
				name: 'STAKING 내역'
			}
			, items: []
			, item_search: this.$storage.init({
				page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_member_cartel_list
					, data: {
						member_number: this.user.member_number
						, inquiry_member_number: this.member.member_number ? this.member.member_number : this.member.post_member_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.page_number
					}
					, type: true
				})
				if(result.success){
					this.items = result.data.cartl_search_result
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		console.log('member', this.member)
		this.getData()
	}
}
</script>