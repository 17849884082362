<template>

	<div class="mt-50 text-left">
		<div class="">
			<div
			>
				<h3 class="mr-10">사용자 검색결과</h3>
			</div>
			<div
				class="box-list mt-20"
			>
				<ul
					v-if="items_member.length > 0"
				>
					<li
						v-for="(item, index) in items_member"
						:key="'item_' + index"
						class="inline-block mr-30 text-center"
						@click="on098(item)"
					>
						<div
							class="square-120 radius-20"
						>
							<img
								:src="$request.upload_url(item.profle_nft_card_img_url)" :alt="item.nickname"
								@error="$event.target.src=require('@/assets/image/@noimage.png')"
								class="width-100 object-cover"
							>
						</div>
						<div class="mt-10">
							<strong>{{ item.nickname }}</strong>
						</div>
					</li>
				</ul>
				<div
					v-else
					class="none"
				>검색된 사용자가 없습니다</div>
			</div>
		</div>

		<div class="mt-30">
			<div
				class="box-title"
			>
				<h3 class="mr-10">카르텔 검색결과</h3>
			</div>
			<div
				class="box-list mt-20"
			>
				<ul>
					<li
						v-for="(cartel, index) in items_cartel"
						:key="'cartel_' + index"
						class="inline-block cursor-pointer mr-30"
						@click="toDetail(cartel)"
					>
						<div
							class="flex-row"
						>
							<div class="square-120 radius-20 mr-10">
								<img
									:src="$request.upload_url(cartel.cartl_img_url)"
									@error="$event.target.src=require('@/assets/image/@noimage.png')"
									class="width-100 object-cover"
								/>
							</div>

							<div
								class="box-info flex-1"
							>
								<div class="title ">{{  cartel.cartl_name }}</div>
								<div class="count mt-10 flex-row"><span class="flex-1">전체 글</span> <span class="flex-2">{{  cartel.count_a | makeComma }}</span></div>
								<div class="count mt-10 flex-row"><span class="flex-1">새글</span> <span class="flex-2">{{  cartel.count_n | makeComma }}</span></div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<mafia098
			v-if="is_098"
			:cartel="items_cartel"
			:user="user"
			:friend="item_user"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 99; background-color: white"
		></mafia098>
	</div>
</template>

<script>
	import mafia098 from "@/view/Cartel/mafia098"
	export default {
		name: 'mafia047'
		, props: ['user']
		, components: { mafia098 }
		, data: function(){
			return {
				program: {
					name: '카르텔 검색'
					, title: '검색'
					, from: 'mafia044'
				}
				, item_search: {
					member_number: this.user.member_number
					, search_value: this.$route.params.search_value
				}
				, is_search: false
				, items_cartel: []
				, items_member: []
				, view_type: ''
				, is_098: false
				, item_user: {}
			}
		}
		, computed: {
			txt_search: function(){
				let t = '검색어를 입력하세요'

				if(this.is_search && this.items_cartel.length == 0 && this.items_member.length == 0){
					t = '검색결과가 없습니다'
				}

				return t
			}
		}
		, methods: {
			getSearch: async function(){
				try{
					if(!this.item_search.search_value){
						throw this.$language.common.error_empty_search_value
					}
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_main_search
						, data: {
							member_number: this.user.member_number
							, srchtext: this.item_search.search_value
						}
						, type: true
					})

					if(result.success){
						this.items_member = result.data.user_search_result
						this.items_cartel = result.data.cartl_search_result
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, getMembers: async function(){

				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_member_list
						, data: this.$storage.init({
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, srchtext: this.item_search.search_value
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						})
						, type: true
					})

					if(result.success){
						this.items_member = result.data.member_list
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, getData: async function(){

				try{
					this.view_type = ''
					if(!this.item_search.search_value){
						//throw '검색어를 입력하세요'
					}
					try{
						this.$bus.$emit('on', true)
						const result = await this.$request.init({
							method: 'post'
							, url: this.$api_url.api_path.get_cartel_total_search
							, data: this.$storage.init({
								member_number: this.user.member_number
								, srchtext: this.item_search.search_value
							})
							, type: true
						})

						if(result.success){
							this.items_member = result.data.user_search_result
							this.items_cartel = result.data.cartl_search_result
						}else{
							throw result.message
						}
					}catch (e) {
						console.log(e)
						this.$bus.$emit('notify', { type: 'error', message: e})
					}finally {
						this.is_search = true
						this.$bus.$emit('on', false)
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, goBack: function(){
				if(this.view_type == 'member'){
					this.view_type = ''
				}else{
					this.$storage.push({ name: this.program.from })
				}
			}
			, on098: function(user){
				console.log('user', user)
				this.is_098 = true
				this.item_user = user
			}
			, toDetail: function(item){
				this.$bus.$emit('to', { name: 'mafia049', params: {idx: item.cartl_number}})
			}
		}
		,created() {
			this.$bus.$emit('onLoad', this.program)
			console.log(this.$route)
			this.getSearch()
		}
		, watch: {
			$route(to){
				this.item_search.search_value = to.params.search_value
				this.getSearch()
			}
		}
	}
</script>