<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="flex-column radius-20 text-left  radius-20 overflow-hidden"
				style="min-width: 240px; max-width: 480px; margin: 0 auto;"
			>
				<div class="bg-primary flex-row justify-space-between items-center pa-10-20">
					<h4 class="color-white">멤버 정보</h4>
					<v-icon
						class="color-white"
						@click="$emit('cancel')"
					>mdi-close-circle</v-icon>
				</div>

				<div class="bg-white pa-20">
					<div class="flex-row items-center">
						<div class="square-64 radius-100 overflow-hidden mr-10">
							<img
								:src="$request.upload_url(item_friend.profle_nft_card_img)" alt="nft card"
								@error="$bus.$emit('onErrorProfileImage', $event)"
								class="width-100"
							>
						</div>
						<div class="flex-1">
							<p class="h5">{{ item_friend.nickname }}</p>
							<p class="mt-10 color-gray">{{ item_friend.state_message }}</p>
						</div>
					</div>
					<div class="mt-30 text-center">
						<span
							v-for="(fav, index) in item_friend.concern_sphere_list"
							:key="'fav_' + index"
							class="inter_40 square-64 mr-10" :class="'inter_40_' + fav.concern_sphere_name.toLowerCase()"
						><em class="hide">{{ fav.concern_sphere_name }}</em></span>
					</div>
					<div
						v-if="is_stat"
						class="mt-30"
					>
						<div
							v-if="item_friend.member_number"
							class="flex-row justify-space-around width-50 ma-auto"
						>
							<span class="tit">방문<em> {{  item_friend.visitor_count }}</em></span>
							<span class="tit">글등록<em> {{ item_friend.writing_articles_count }}</em></span>
						</div>
					</div>

					<div
						v-if="item_friend.member_number"
						class="mt-30 text-center"
					>
						<div
							v-if="item_friend.self_fg != 'Y'"
						>
							<button
								v-if="is_friend"
								class="btn btn-secondary radius-20"
								@click="onConfirm(false)"
							>친구 취소</button>
							<button
								v-else
								class="btn btn-primary radius-20"
								@click="onConfirm(true)"
							>친구 추가</button>
						</div>

						<div class="mt-20">
							<button
								v-if="is_stat"
								@click="is_0982 = true"
								class="mr-30 under-line"
							>작성글 확인</button>
							<button
								@click="is_0981 = true"
								class="under-line"
							>가입 카르텔</button>
						</div>
					</div>
					<!-- //친구추가-->
				</div>
			</div>

			<mafia0981
				v-if="is_0981"
				:user="user"
				:member="item_user"
				:cartel="cartel"

				@cancel="is_0981 = false"
			></mafia0981>

			<mafia0982
				v-if="is_0982"
				:user="user"
				:member="item_user"
				:cartel="cartel"

				@cancel="is_0982 = false"
			></mafia0982>

		</template>
	</PopupLayer>
</template>

<script>
	import mafia0981 from "@/view/Cartel/mafia098-1"
	import mafia0982 from "@/view/Cartel/mafia098-2"
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'mafia098'
		, components: {PopupLayer, mafia0981, mafia0982}
		, props: ['cartel', 'user', 'friend']
		, data: function(){
			return {
				program: {
					name: '사용자 검색 정보'
					, title: '사용자 검색 정보'
					, not_footer: true
					, not_header: true
					, type: 'cartel_sub'
				}
				, is_0981: false
				, is_0982: false
				, item_user: this.friend
				, confirm_type: ''
				, is_confirm: false
				, item_friend: { }
				, is_notify: true
			}
		}
		, computed: {
			is_friend: function(){
				let t = false
				if(this.item_friend.member_friend_fg == 'Y'){
					t = true
				}
				return t
			}
			, is_stat: function(){
				let t = false
				if(this.cartel.cartl_number){
					t = true
				}
				return t
			}
		}
		, methods: {
			onConfirm: function(type){
				this.confirm_type = type
				this.$bus.$emit('onFriend', { type: type, item_friend: this.item_friend})
				this.$bus.$on('callBack', (e) => {
					console.log('callBack', e)
					if(e.type == 'postFriend'){
						if(e.result){
							this.getMyFriend()
						}
					}

					this.$bus.$off('callBack')
				})
			}
			, getMyFriend: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_member_info
						, data: {
							member_number: this.user.member_number
							, inquiry_member_number: this.friend.member_number ? this.friend.member_number : this.friend.post_member_number
							, cartl_number: this.cartel.cartl_number
						}
						, type: true
					})

					if(result.success){
						this.item_friend = result.data.MmbBasicInfo
						this.item_friend.concern_sphere_list = result.data.concern_sphere_list
					}else{
						this.item_friend = {
							nickname: result.message
						}
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.cartel.cartl_number = this.$route.params.idx
			this.getMyFriend()
		}
	}
</script>

<style>
.subindex_mainbox .myintroduce .name {
	overflow: unset !important;
	text-overflow: unset !important;
	word-break: break-all;
	white-space: normal !important;
}
</style>